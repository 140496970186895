const useScrollTo = () => {
	const content = ref<HTMLDivElement>();
	const scrollToContent = (offset = 0) => {
		if (!content.value) {
			return;
		}

		window.scrollTo({
			top: content.value.getBoundingClientRect().y + window.scrollY + offset,
			behavior: "smooth"
		});
	};

	return {
		scrollToContent,
		content
	};
};

export default useScrollTo;
